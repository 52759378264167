import { Link } from 'react-router-dom';
import Password from './Password';
import { useTranslation } from 'react-i18next';

const Setting = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12 h-screen">
        {/* Sidebar (Menú) */}
        <aside className="col-span-3 bg-gray-200 text-cyan p-4 p-2">
          <h2 className="text-lg font-bold mb-4"></h2>
          <nav className="space-y-3">
            <Link
              className="block px-4 py-3 rounded-lg bg-gray-300 hover:bg-gray-400 transition duration-200 text-center font-semibold"
              to={`#`}>
                {t('password.password')}
            </Link>
          </nav>
        </aside>

        {/* Contenido Principal */}
        <main className="col-span-9 p-6 bg-gray-100">
          <h1 className="text-2xl font-bold mb-4"></h1>

          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
            <Password></Password>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Setting;

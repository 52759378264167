import ProfileShow from './ProfileShow';
import ProfileSideMenu from './ProfileSideMenu';

const Profile = () => {
  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12 h-screen">
        {/* Sidebar (Menú) */}
        <aside className="col-span-3 bg-gray-200 text-cyan p-4 p-2">
          <h2 className="text-lg font-bold mb-4"></h2>
          <nav className="space-y-3">
          <ProfileSideMenu></ProfileSideMenu>
          </nav>  
        </aside>
        {/* Contenido Principal */}
        <main className="col-span-9 p-6 bg-gray-100">
          <h1 className="text-2xl font-bold mb-4">Profile</h1>
            <ProfileShow></ProfileShow>
        </main>
      </div>
    </div>
  )
}

export default Profile;

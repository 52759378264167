import axios, { AxiosResponse, AxiosError } from 'axios';
import React ,{ useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';

interface Comment {
  author: string;
  content: string;
  posted_at?: Date
}

interface IFormInput {
  author: string;
  content: string;
}

interface AddCommentFormProps {
  addNewComment: (newComment: Comment) => void;
}

const AddCommentForm: React.FC<AddCommentFormProps> = ({ addNewComment }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const location = useLocation();
  const { state } = location;
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  const toggleProfileForm = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const onSubmit = handleSubmit((values) => {
    const author = values.author;
    const content = values.content;

    const configuration = {
      url: API_BASE_URL + `/article/${state.article._id}/add_comment`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
      data: {
        author,
        content
      },
    };

    axios.request(configuration).then((res: AxiosResponse) => {
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        const newComment = { author, content };
        addNewComment(newComment);
        setSuccessMessage('Comment sent successfully!');
        setTimeout(() => {
          toggleProfileForm();
        }, 2000);
      }
    }).catch((err: AxiosError) => {
      console.log(err);
    });
  });

  return (
    <div className='container mx-auto'>
      <form onSubmit={onSubmit} className='space-y-6'>
        {errorMessage && <p className='peer-invalid:visible text-red-700 font-light'>{errorMessage}</p>}
        {successMessage && <p className='peer-invalid:visible text-green-700 font-light'>{successMessage}</p>}
        <div>
          <div className='flex items-center justify-between'>
            <label className='block text-sm font-medium leading-6 text-gray-900'>{t('articles.comment.username')}</label>
          </div>
          <div className='mt-2'>
            <input
              {...register('author', { required: true, minLength: 3 })}
              aria-invalid={errors.author ? 'true' : 'false'}
              className='block p-2.5 w-50% rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            />
            {errors.author?.type === 'required' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('articles.comment.errors.author_name_is_required')}</p>
            )}

            {errors.author?.type === 'minLength' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('articles.comment.errors.author_name_length')}</p>
            )}
          </div>
        </div>
        <div>
          <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>{t('articles.comment.content')}</label>
          <textarea
            {...register('content')}
            rows={4}
            className='block p-2.5 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder={t('articles.comment.leave_a_comment')}>
          </textarea>
        </div>
        <div>
          <button
            type='submit'
            className='flex w-10% justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >{t('articles.comment.save_comment')}</button>
        </div>
      </form>
    </div>
  );
};

export default AddCommentForm;

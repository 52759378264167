// Layout.js
import React, { ReactNode } from 'react';

import Menu  from './Menu';

interface LayoutProps {
  children?: ReactNode;
}


const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div  className='dark:bg-slate-800 bg-white shadow-xl'>
      {/* Your common header content goes here */}
      <header className='absolute inset-x-0 top-0 z-50'>
        <Menu></Menu>
      </header>

      <div className='container w-full md:max-w-3xl mx-auto pt-20'>
        {children}
      </div>
    </div>
  );
};

export default Layout;

import { useState } from 'react';
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from './auth';
import { useNavigate, useLocation } from 'react-router-dom';
import API_BASE_URL from '../config';

interface IFormInput {
  password: string;
  password_confirmation: string;
}

const Password = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<IFormInput>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || '/home';
  const password = watch("password");
  

  const onSubmit = handleSubmit((values) => {
    const password = values.password;

    const configuration = {
    url: API_BASE_URL + '/password',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
    },
    data: {
      password
    },
    }

    axios.request(configuration)
      .then((result) => {

        if (!result.data.token) {
        setErrorMessage("Resultado");
      }
       auth?.login(result.data._doc);

       localStorage.setItem('jwt', result.data.token);
       navigate(redirectPath, { replace: true });
      }).catch(err => {
        console.log(err);
        setErrorMessage("Algun error");
    })
  })

  return (
    <div className='flex min-h-full flex-col justify-center px-6 py-1 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>{t('password.update_password')}</h2>
      </div>
      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        {errorMessage && <p className='peer-invalid:visible text-red-700 font-light'>{errorMessage}</p>}
        <form onSubmit={onSubmit} className='space-y-6'>
          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>{t('password.password')}</label>
            </div>
            <div className='mt-2'>
              <input
                {...register('password', {
                  required: 'La contraseña es obligatoria'
                })}
                aria-invalid={errors.password ? 'true' : 'false'}
                type='password'
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>{t('password.password_confirmation')}</label>
            </div>
            <div className='mt-2'>
              <input
                {...register('password_confirmation', { 
                  required: t('password.password_confirmation'),
                  validate: (value) => value === password || t('password.password_does_not_match'),
                  minLength: {
                    value: 8,
                    message: t('password.minimum_length'),
                  },
                  maxLength: {
                    value: 20,
                    message: t('password.maximum_length'),
                  },
                 })}
                aria-invalid={errors.password ? 'true' : 'false'}
                type='password'
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
              {errors.password_confirmation && <p className="text-red-500">{errors.password_confirmation.message}</p>}
            </div>
          </div>
          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >{t('password.change_password')}</button>
          </div>

        </form>
        </div>
    </div>
  )
}

export default Password

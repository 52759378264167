import React from 'react';
import { TextEditor } from './TextEditor';
import { useTranslation } from 'react-i18next';

interface ArticleFormContentProps {
  formik: any; // Replace 'any' with the actual type of formik
}

const ArticleForm: React.FC<ArticleFormContentProps> = ({ formik }) => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  return (
    <div className="container mx-auto">
      <div className="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
        <form onSubmit={formik.handleSubmit}>
          <label
            htmlFor="Title"
            className='block text-sm font-medium leading-6 text-gray-900'
          >{t('articles.form.title')}</label>
          <br />
          <input
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            name='title'
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          {formik.touched.title && formik.errors.title && <div className='text-red-500 text-sm'>{formik.errors.title}
          </div>}

          <label
            htmlFor="Subheading"
            className='block text-sm font-medium leading-6 text-gray-900'
          >{t('articles.form.subheading')}</label>
          <br />
          <input
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            name='subheading'
            onChange={formik.handleChange}
            value={formik.values.subheading}
          />
          {formik.touched.subheading && formik.errors.subheading && <div className='text-red-500 text-sm'>{formik.errors.subheading}
          </div>}

          <label
            htmlFor="Video"
            className='block text-sm font-medium leading-6 text-gray-900'
          >Video</label>
          <br />
          <input
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            name='video'
            onChange={formik.handleChange}
            value={formik.values.video}
          />
          {formik.touched.video && formik.errors.video && <div className='text-red-500 text-sm'>{formik.errors.video}
          </div>}

          <br />
          <label
            htmlFor="Content"
            className='block text-sm font-medium leading-6 text-gray-900'
          >{t('articles.form.content')}</label>
          <br />
          <TextEditor
            setFieldValue={(val) => formik.setFieldValue('content', val)}
            value={formik.values.content}
          ></TextEditor>
          {formik.touched.content && formik.errors.content && <div className='text-red-500 text-sm'>{formik.errors.content}</div>}
          <button
            type='submit'
            className='flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            {t('articles.form.save_article')}
          </button>
        </form>
      </div>
    </div>
    )
}

export default ArticleForm


let API_BASE_URL = '';

console.log('process.env.NODE_ENV', process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  API_BASE_URL = 'https://codefithub-backend-production.onrender.com';
} else if ((process.env.NODE_ENV as string) === 'staging') {
  API_BASE_URL = 'https://codefithub-backend-v1-0.onrender.com';
} else {
  API_BASE_URL ='http://localhost:5000';
}

console.log('API_BASE_URL', API_BASE_URL);

export default API_BASE_URL;

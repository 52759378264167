import { Link } from "react-router-dom";
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useAuth } from './auth';
import LanguageSelector from './LanguageSelector';
import LoggedInNavbar from './LoggedInNavbar';
import AnonymousUserNavbar from './AnonymousUserNavbar';
import React from 'react';

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const auth = useAuth();

  return (
    <div>
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/home" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              className='h-8 w-auto border border-black rounded-full'
              src={require('../assets/images/logo-fitness.png')}
              sizes='600x600'
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Logged in user */}
        {
          auth?.user ?
          <LoggedInNavbar></LoggedInNavbar>
            :
            null
        }

        {/* Anonymous user */}
        {
          !auth?.user ?
          <AnonymousUserNavbar></AnonymousUserNavbar>
            :
            null
        }
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {
              !auth?.user ?
                <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/login">
                  Log in <span aria-hidden="true">&rarr;</span>
                </Link>
                :
                null
            }

            {
              !auth?.user ?
                <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/signup">
                  Sign up <span aria-hidden="true">&rarr;</span>
                </Link>
                :
                null
            }

            {
              auth?.user ?
                <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/logout">
                  Logout <span aria-hidden="true">&rarr;</span>
                </Link>
                :
                null
            }
          </div>
        <div>
          <LanguageSelector></LanguageSelector>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                className="h-8 w-auto"
                src={require('../assets/images/logo-fitness.png')}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/home">Home</Link>

                <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/published-articles">Articles</Link>

                <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/videos">Videos</Link>

                  <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/about">About</Link>

                  {
                    auth?.user ?
                      <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/dashboard">Dashboard</Link>
                      :
                      null
                  }
                  {
                    auth?.user ?
                      <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/profile">Profile</Link>
                      :
                      null
                }
                </div>
              <div className="py-6">
                {
                  !auth?.user ?
                    <Link className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/login">
                      Log in <span aria-hidden="true">&rarr;</span>
                    </Link>
                    :
                    null
                }

                {
                  !auth?.user ?
                    <Link className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/signup">
                      Sign up <span aria-hidden="true">&rarr;</span>
                    </Link>
                    :
                    null
                }

                {
                  auth?.user ?
                    <Link className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/logout">
                      Logout <span aria-hidden="true">&rarr;</span>
                    </Link>
                    :
                    null
                }
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>


    </div>
      )
};

export default Navbar;

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Query from '../requests/Articles/Query';
import { ResultType } from '../types/ArticleTypes';
import { useNavigate } from 'react-router-dom';
import { alertMessages, textSize } from '../alerts/Messages';
import ArticleForm from './ArticleForm';
import DashboardSideMenu from './DashboardSideMenu';

const schema = Yup.object().shape({
  title: Yup.string().min(textSize.titleMinSize, alertMessages.textTooShort).max(textSize.titleMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
  subheading: Yup.string().min(textSize.subheadingMinSize, alertMessages.textTooShort).max(textSize.subheadingMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
  video: Yup.string().min(textSize.contentMinSize, alertMessages.textTooShort).max(textSize.contentMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
  content: Yup.string().min(textSize.contentMinSize, alertMessages.textTooShort).max(textSize.contentMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired)
});

const ArticleAddForm = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { title: '', subheading: '', video: '', content: '' },
    onSubmit: (values) => {
      const title = values.title;
      const subheading = values.subheading;
      const video = values.video;
      const content = values.content;
      const url = '/article';
      const verb = 'post';

      const query = new Query(url, verb, title, subheading, video, content);
        query.postOrPatch()
          .then((result: ResultType) => {
            if (result.status === 'ok') {
              setTimeout(() => {
                navigate('/dashboard');
              }, 2000);
            }
        })
          .catch((error) => {
          console.error(error);
        });
      },
    validationSchema: schema
  });

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12 h-screen">
        <aside className="col-span-3 bg-gray-200 text-cyan p-4">
          <h2 className="text-lg font-bold mb-4"></h2>
          <nav className="space-y-2">
          <DashboardSideMenu></DashboardSideMenu>
          </nav>  
        </aside>

        {/* Contenido Principal */}
        <main className="col-span-9 p-6 bg-gray-100">
          <h1 className="text-2xl font-bold mb-4"></h1>
          <ArticleForm formik={formik} />
          <h1></h1>
        </main>
      </div>
    </div>
    )
}

export default ArticleAddForm

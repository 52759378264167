import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DashboardSideMenu = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  return (
    <div>
      {/* Sidebar (Menú) */}
        <Link
          className="block px-4 py-3 rounded-lg bg-gray-300 hover:bg-gray-400 transition duration-200 text-center font-semibold mb-2"
          to={`/dashboard`}>
              {t('dashboard.list')}
        </Link>
        <Link
          className="block px-4 py-3 rounded-lg bg-gray-300 hover:bg-gray-400 transition duration-200 text-center font-semibold"
          to={`/article/new`}>
            {t('dashboard.new')}
        </Link>

    </div>
    )
}

export default DashboardSideMenu

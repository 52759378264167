import React, { useEffect, useState } from 'react';
import API_BASE_URL from '../config';

interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
}

const VideoList: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        const response = await fetch(API_BASE_URL + '/videos');
        if (!response.ok) {
          throw new Error(`Error al obtener videos: ${response.statusText}`);
        }
        const data: Video[] = await response.json();
        console.log(data);
        setVideos(data);
      } catch (err: unknown) {
        const message = err instanceof Error ? err.message : 'Error desconocido';
        setError(message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) {
    return <div className="text-center text-lg font-medium mt-8">Cargando videos...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 text-lg font-medium mt-8">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {videos.map((video) => (
          <div
            key={video.id}
            className="bg-white border border-gray-200 shadow-md rounded-lg overflow-hidden"
          >
            <img src={video.thumbnail} alt={video.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-lg font-bold text-gray-800">{video.title}</h3>
              <p className="text-sm text-gray-600 mt-2 line-clamp-2">{video.description}</p>
              <a
                href={`https://www.youtube.com/watch?v=${video.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block mt-4 text-blue-600 hover:underline text-sm"
              >
                Ver en YouTube
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoList;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Los recursos de traducción
const resources = {
 es: {
  translation: {
   About: 'Acerca de',
   Articles: 'Artículos',
   Dashboard: 'Panel',
   Home: 'Inicio',
   Welcome: 'Bienvenidos',
   Profile: 'Perfil',
   Settings: 'Configuración',
   Contact: 'Contacto',
   Messages: 'Mensajes',
   answer: {
    no: 'No',
    yes: 'Si',
   },
   articles: {
    published_on: 'Publicado el',
    by: 'por',
    continue_reading: 'continuar leyendo',
    comments: 'comentarios',
    no_comments_yet: 'No hay comentarios aun',
    comment: {
     username: 'Nombre del usuario',
     content: 'Contenido',
     save_comment: 'Enviar comentario',
     leave_a_comment: 'Dejar un comentario',
     errors: {
      author_name_is_required: 'Nombre es requerido',
      author_name_length: 'El nombre debe tener al menos 3 caracteres',
     },
    },
    form: {
     title: 'Título',
     subheading: 'Subtitulo',
     video: 'Video',
     content: 'Contenido',
     save_article: 'Grabar Artículo',
     upload_image_for: 'Subir imagen para',
     load_image: 'Cargar Imágen',
    },
   },
   dashboard: {
    dashboard: 'Panel',
    new: 'Nuevo',
    title: 'Titulo',
    published: 'Publicado',
    published_at: 'Publicado el',
    image: 'Imagen',
    actions: 'Acciones',
    change_image: 'Cambiar Imagen',
    edit: 'Editar',
    delete: 'Eliminar',
    hide: 'Esconder',
    publish: 'Publicar',
    upload_image: 'Cargar Imagen',
    search_for_items: 'Buscar por nombre',
    list: 'Artículos',
   },
   profile: {
    about_me: 'Sobre mí',
    profile: 'Perfil',
    edit_profile: 'Editar Perfil',
    show_profile: 'Ver Perfil',
    username: 'Nombre de Usuario',
    email: 'Correo Electrónico',
    bio: 'Biografía',
    save_profile: 'Guardar Perfil',
    general_information: 'Información General',
   },
   password: {
    password: 'Contraseña',
    show_password_form: 'Mostrar formulario de contraseña',
    hide_password_form: 'Ocultar formulario de contraseña',
    change_password: 'Cambiar contraseña',
    password_confirmation: 'Debes confirmar la contraseña',
    password_does_not_match: 'La contraseña no coincide',
    minimum_length: 'La contraseña debe tener al menos 8 caracteres',
    maximum_length: 'La contraseña no puede superar los 20 caracteres',
    update_password: 'Actualizar contraseña',
   },
   contact: {
    username: 'Nombre',
    email: 'Email',
    content: 'Contenido',
    send_message: 'Enviar Mensaje',
    created_at: 'Creado el',
    write_your_reason_of_contact_here: 'Escribe aquí tu razón del contacto',
    errors: {
     author_name_is_required: 'Nombre es requerido',
     author_name_length: 'El nombre debe tener al menos 3 caracteres',
     email_is_required: 'Email es requerido',
     email_is_not_valid: 'Email no es valido',
     content_is_required: 'El contenido es requerido',
     min_length: 'Contenido mínimo no alcanzado',
    },
   },
  },
 },
 en: {
  translation: {
   About: 'About',
   Articles: 'Articles',
   Dashboard: 'Dashboard',
   Home: 'Home',
   Welcome: 'Welcome',
   Profile: 'Profile',
   Settings: 'Settings',
   Contact: 'Contact',
   Messages: 'Messages',
   answer: {
    no: 'No',
    yes: 'Yes',
   },
   articles: {
    published_on: 'Published on',
    by: 'by',
    continue_reading: 'continue reading',
    comments: 'comments',
    no_comments_yet: 'No comments yet',
    comment: {
     username: 'Username',
     content: 'Content',
     save_comment: 'Save Comment',
     leave_a_comment: 'Leave a comment',
     errors: {
      author_name_is_required: 'Author name is required',
      author_name_length: 'Author name length must be at least 3 characters',
     },
    },
    form: {
     title: 'Title',
     subheading: 'Subheading',
     video: 'Video',
     content: 'Content',
     save_article: 'Save Article',
     upload_image_for: 'Upload image for',
     load_image: 'Load Image',
    },
   },
   dashboard: {
    dashboard: 'Dashboard',
    new: 'New',
    title: 'Title',
    published: 'Published',
    published_at: 'Published At',
    image: 'Image',
    actions: 'Actions',
    change_image: 'Change Image',
    edit: 'Edit',
    delete: 'Delete',
    hide: 'Hide',
    publish: 'Publish',
    upload_image: 'Upload Image',
    search_for_items: 'Search for items',
    list: 'Articles',
   },
   profile: {
    about_me: 'About Me',
    profile: 'Profile',
    edit_profile: 'Edit Profile',
    show_profile: 'Show Profile',
    username: 'Username',
    email: 'Email',
    bio: 'Bio',
    save_profile: 'Save Profile',
    general_information: 'General Information',
   },
   password: {
    password: 'Password',
    show_password_form: 'Show password form',
    hide_password_form: 'Hide password form',
    change_password: 'Change password',
    password_confirmation: 'You must confirm the password',
    password_does_not_match: 'Password does not match',
    minimum_length: 'Password must be at least 8 characters long',
    maximum_length: 'Password cannot exceed 20 characters',
    update_password: 'Update password',
   },
   contact: {
    username: 'Username',
    email: 'Email',
    content: 'Content',
    send_message: 'Send Message',
    write_your_reason_of_contact_here: 'Write your reason of contact here',
    created_at: 'Created at',
    errors: {
     author_name_is_required: 'Name is required',
     author_name_length: 'Name at least 3 characters long',
     email_is_required: 'Email is required',
     email_is_not_valid: 'Email is not valid',
     content_is_required: 'Content is required',
     min_length: 'Minimum lengt required at lest 100 characters',
    },
   },
  },
 },
 pt: {
  translation: {
   About: 'Sobre',
   Articles: 'Artigos',
   Dashboard: 'Painel',
   Home: 'Início',
   Welcome: 'Bem-vindos',
   Profile: 'Perfil',
   Settings: 'Configuração',
   Contact: 'Contato',
   Messages: 'Mensagens',
   answer: {
    no: 'Não',
    yes: 'Sim',
   },
   articles: {
    published_on: 'Publicado em',
    by: 'por',
    continue_reading: 'continuar lendo',
    comments: 'comentários',
    no_comments_yet: 'Ainda não há comentários',
    comment: {
     username: 'Nome do usuário',
     content: 'Conteúdo',
     save_comment: 'Enviar comentário',
     leave_a_comment: 'Deixar um comentário',
     errors: {
      author_name_is_required: 'O nome do autor é obrigatório',
      author_name_length:
       'O comprimento do nome do autor deve ser de pelo menos 3 caracteres',
     },
    },
    form: {
     title: 'Título',
     subheading: 'Subtítulo',
     video: 'Vídeo',
     content: 'Conteúdo',
     save_article: 'Salvar Artigo',
     upload_image_for: 'Carregar imagem para',
     load_image: 'Carregar Imagem',
    },
   },
   dashboard: {
    dashboard: 'Painel',
    new: 'Novo',
    title: 'Título',
    published: 'Publicado',
    published_at: 'Publicado em',
    image: 'Imagem',
    actions: 'Ações',
    change_image: 'Mudar Imagem',
    edit: 'Editar',
    delete: 'Excluir',
    hide: 'Esconder',
    publish: 'Publicar',
    upload_image: 'Carregar Imagem',
    search_for_items: 'Procurar por nome',
    list: 'Artigos',
   },
   profile: {
    about_me: 'Sobre mim',
    profile: 'Perfil',
    edit_profile: 'Editar Perfil',
    show_profile: 'Ver Perfil',
    username: 'Nome de Usuário',
    email: 'Email',
    bio: 'Biografia',
    save_profile: 'Salvar Perfil',
    general_information: 'Informações Gerais',
   },
   password: {
    password: 'Senha',
    show_password_form: 'Mostrar formulário de senha',
    hide_password_form: 'Ocultar formulário de senha',
    change_password: 'Alterar a senha',
    password_confirmation: 'Você deve confirmar a senha',
    password_does_not_match: 'A senha não corresponde',
    minimum_length: 'A senha deve ter pelo menos 8 caracteres',
    maximum_length: 'A senha não pode exceder 20 caracteres',
    update_password: 'Atualizar senha',
   },
   contact: {
    username: 'Nome de Usuário',
    email: 'Email',
    content: 'Conteudo',
    send_message: 'Enviar Messagem',
    write_your_reason_of_contact_here: 'Escreva aqui o seu motivo de contato',
    created_at: 'Criado em',
    errors: {
     author_name_is_required: 'Nome é obrigatório',
     author_name_length: 'Nomeie pelo menos 3 caracteres',
     email_is_required: 'E-mail é obrigatório',
     email_is_not_valid: 'E-mail não é válido',
     content_is_required: 'Conteúdo é obrigatório',
     min_length: 'Comprimento mínimo exigido de pelo menos 100 caracteres',
    },
   },
  },
 },
 fr: {
  translation: {
   About: 'À propos',
   Articles: 'Articles',
   Dashboard: 'Tableau de bord',
   Home: 'Accueil',
   Welcome: 'Bienvenue',
   Profile: 'Profil',
   Settings: 'Paramètres',
   Contact: 'Contact',
   Messages: 'Messages',
   answer: {
    no: 'Non',
    yes: 'Oui',
   },
   articles: {
    published_on: 'Publié le',
    by: 'par',
    continue_reading: 'continuer à lire',
    comments: 'commentaires',
    no_comments_yet: 'Pas encore de commentaires',
    comment: {
     username: "Nom d'utilisateur",
     content: 'Contenu',
     save_comment: 'Envoyer commentaire',
     leave_a_comment: 'Laisser un commentaire',
     errors: {
      author_name_is_required: "Le nom de l'auteur est requis",
      author_name_length:
       "La longueur du nom de l'auteur doit être d'au moins 3 caractères",
     },
    },
    form: {
     title: 'Titre',
     subheading: 'Sous-titre',
     video: 'Vidéo',
     content: 'Contenu',
     save_article: "Enregistrer l'article",
     upload_image_for: 'Télécharger une image pour',
     load_image: 'Télécharger une image',
    },
   },
   dashboard: {
    dashboard: 'Tableau de bord',
    new: 'Nouveau',
    title: 'Titre',
    published: 'Publié',
    published_at: 'Publié le',
    image: 'Image',
    actions: 'Actions',
    change_image: "Changer d'image",
    edit: 'Éditer',
    delete: 'Supprimer',
    hide: 'Cacher',
    publish: 'Publier',
    upload_image: 'Télécharger une image',
    search_for_items: 'Rechercher par nom',
    list: 'Articles',
   },
   profile: {
    about_me: 'Sur moi',
    profile: 'Profil',
    edit_profile: 'Modifier le profil',
    show_profile: 'Voir le profil',
    username: "Nom d'utilisateur",
    email: 'Email',
    bio: 'Biographie',
    save_profile: 'Enregistrer le profil',
    general_information: 'Informations générales',
   },
   password: {
    password: 'Mot de passe',
    show_password_form: 'Afficher le formulaire de mot de passe',
    hide_password_form: 'Masquer le formulaire de mot de passe',
    change_password: 'Changer le mot de passe',
    password_confirmation: 'Vous devez confirmer le mot de passe',
    password_does_not_match: 'Le mot de passe ne correspond pas',
    minimum_length: 'Le mot de passe doit comporter au moins 8 caractères',
    maximum_length: 'Le mot de passe ne peut pas dépasser 20 caractères',
    update_password: 'Mettre à jour le mot de passe',
   },
   contact: {
    username: "Nom d'utilisateur",
    email: 'Email',
    content: 'Contenu',
    send_message: 'Envoyer un message',
    write_your_reason_of_contact_here: 'Écrivez ici le motif de votre contact',
    created_at: 'Créé à',
    errors: {
     author_name_is_required: 'Le nom est requis',
     author_name_length: 'Nommez au moins 3 caractères',
     email_is_required: 'Un e-mail est requis',
     email_is_not_valid: "L'email n'est pas valide",
     content_is_required: 'Le contenu est requis',
     min_length: 'Longueur minimale requise au moins 100 caractères',
    },
   },
  },
 },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Idioma por defecto
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

import axios from 'axios';
import { Link } from 'react-router-dom';
import PublishedAtInfo from './utils/PublishedAtInfo';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';

export type ArticleType = {
  _id: string;
  title: string;
  video?: string | null;
  content?: string | null;
  image: string | null;
  published: boolean;
  published_at: string;
  imageURL: string;
};

interface PublishedArticleContentProps {
  article: any; // Replace 'any' with the actual type of formik
  imageURL: string;
}

const PublishedArticle: React.FC<PublishedArticleContentProps> = ({ article }) => {
  const [data, setData] = useState<ArticleType>();
  const url = `/article/${article._id}/show`;
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: API_BASE_URL + url,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        }

        axios.request<ArticleType>(configuration).then(
          (res) => {
            setData(res.data)
          }
          ).catch((err) => {
            console.error(err);
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [url]); // Empty dependency array means this effect runs once on mount

  return (
    <div className="container my-4 mx-auto md:px-6 bg-gray-100">
      <section className="mb-2">
        <div className="ml-2 mb-2 flex items-center">
          <PublishedAtInfo published_at={String(article.published_at)} ></PublishedAtInfo>
        </div>
          <div className="h-65 grid grid-cols-2 gap-10 content-start">
            {data?.imageURL && (
              <div className="pull-center">
                <img
                  src={
                    data?.imageURL
                      ? `${data?.imageURL}`
                      : require('../assets/images/home_1.jpg')
                  }
                  className="w-96 h-44 mr-4 border-2 border-black rounded-lg"
                  alt=""
                />
              </div>
            )}

            <div className="pull-left">
              <h1 className="mb-6 text-3xl text-left">{article.title}</h1>
              <h4 className="mb-6 text-1xl text-gray-500 text-left">
                <div dangerouslySetInnerHTML={{ __html: `${article.subheading ?? ''}` }} />
              </h4>
              <div className="text-right">
                <Link
                  className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  to={`/article/${article._id}/show`}
                  state={{ article }}
                >
                  {t('articles.continue_reading')}
                </Link>
              </div>
            </div>
          </div>
      </section>
    </div>
  )
}

export default PublishedArticle

import { useTranslation } from 'react-i18next';

type PublishedAtInfoProps = {
  published_at: string;
};

const PublishedAtInfo: React.FC<PublishedAtInfoProps> = ({ published_at }) => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  const publishedAt = (): string => {
    const date = new Date(published_at);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Format date as "dd/mm/yyyy"
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
  }

return (
  <div className="mb-6 flex items-center">
    <img
      src={require('../../assets/images/logo-fitness.png')}
      className="mr-2 h-8 rounded-full"
      alt="avatar"
      loading="lazy"
    />
    <div className="flex items-center space-x-2 ml-2">
      <div className="flex items-center">
        {t('articles.published_on')} {publishedAt()} {t('articles.by')}
      </div>
      <div className="flex items-center ml-2">
        <a
          href="https://www.youtube.com/@AnittaMendezFitness"
          className="font-medium text-blue-600"
          title="Ir al canal de Anitta"
          target="_blank"
          rel="noopener noreferrer"
        >
          Anitta Mendez Fitness
        </a>
      </div>
      <div className="flex items-center ml-2">
        <img
          src={require('../../assets/images/youtube.png')}
          className="rounded-full w-6 h-6"
          alt="youtube"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  );
}

export default PublishedAtInfo

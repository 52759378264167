import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React from 'react';

const LoggedInNavbar: React.FC = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  return (
    <div>
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="hidden lg:flex lg:gap-x-12">

          {/* Dashboard */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/dashboard">{t('Dashboard')}</Link>

          {/* Profile */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/profile">{t('Profile')}</Link>

          {/* Settings */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/settings/get_gck">{t('Settings')}</Link>

          {/* Messages */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/contact/messages">{t('Messages')}</Link>
        </div>
      </nav>
    </div>
  )
};

export default LoggedInNavbar;

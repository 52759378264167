import AddCommentForm from './AddCommentForm';
import axios from 'axios';
import moment from 'moment';
import PublishedAtInfo from './utils/PublishedAtInfo';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';

interface Comment {
  author: string;
  content: string;
  posted_at?: Date;
}

export type ArticleType = {
  _id: string;
  subheading?: string;
  title: string;
  video?: string | null;
  content?: string | null;
  image: string | null;
  published: boolean;
  published_at: string;
  imageURL: string;
  comments: Comment[];
};

// Tip: Enable this next sort code on render comments when I want to order from newer to older comments
// .sort((a, b) => {
//   const dateA = a.posted_at ? new Date(a.posted_at).getTime() : 0; // 0 can represent a very old date
//   const dateB = b.posted_at ? new Date(b.posted_at).getTime() : 0; // 0 can represent a very old date
//   return dateB - dateA;
// })

const PublishedArticleShow: React.FC = () => {
  const [data, setData] = useState<ArticleType | null>(null);
  const location = useLocation();
  const { state } = location;
  const url = `/article/${state.article._id}/show`;
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: API_BASE_URL + url,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        }

        axios.request<ArticleType>(configuration).then(
          (res: any) => {
            setData({
              ...res.data['article'][0],
              imageURL: res.data['imageURL'],
              comments: res.data['article'][0].comments || []
            });
          }
          ).catch((err) => {
            console.error(err);
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [url]);

  const commentedAt = (commented_at: Date): string => {
    const mongoDate = new Date(commented_at);
    const now = new Date();

    const duration = moment.duration(moment(now).diff(moment(mongoDate)));

    if (duration.asHours() < 24) {
      if (duration.hours() < 1) {
        return `${duration.minutes()} minutes ago.`;
      } else if (duration.minutes() < 1) {
        return `${duration.hours()} hours ago.`;
      } else {
        return `${duration.hours()} hours and ${duration.minutes()} minutes ago.`;
      }
    } else {
      const date = new Date(commented_at);

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
      return formattedDate;
    }
   }

   const addNewComment = (newComment: Comment) => {
    if (data) {
      setData({
        ...data,
        comments: data.comments ? [...data.comments, newComment] : [newComment],
      });
    }
  };

  return (
    <div className="container my-2 mx-auto md:px-6">
      <section className="mb-32">
        {
          data?.imageURL && (
            <div className='flex justify-start max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto'>
              <img
                alt=''
                className='w-full h-auto border-2 border-black rounded-lg'
                src={ data?.imageURL ? `${data?.imageURL}` : require('../assets/images/home_1.jpg')}
              />
            </div>
            )
        }

        <div className="mb-6 flex items-center">
          <PublishedAtInfo published_at={String(state.article.published_at)} ></PublishedAtInfo>
        </div>

        <h1 className="mb-6 text-3xl font-bold">
          {data?.title}
        </h1>

        <h4 className="mb-6 text-2xl">
          {data?.subheading}
        </h4>

        <h1 className="mb-6 text-3xl font-bold">
          <div dangerouslySetInnerHTML={{ __html: data?.video || 'No hay video' }} />
        </h1>

        <p>
          <div dangerouslySetInnerHTML={{ __html: `${data?.content}` }} />
        </p>
      </section>
      <AddCommentForm addNewComment={addNewComment} />
      <div>{data?.comments?.length} {t('articles.comments')}</div>


      {data?.comments?.length ? (
        data.comments.map((comment: Comment, index: number) => (
          <div key={index} className="w-full mx-auto border px-6 py-4 rounded-lg">
            <div>
              <img
              src={require('../assets/images/default-avatar.png')}
              alt=''
              className='w-12 h-12 rounded-full mr-4'
            />
              <div>
                <div className="text-lg font-medium">{comment.author}</div>
                <div className="text-gray-500">{commentedAt(comment?.posted_at ?? new Date())}</div>
              </div>
            </div>
            <p className="text-lg leading-relaxed mb-6">{comment.content}</p>
            <div className="flex justify-between items-center">
              {/* <div>
                <a href="#" className="text-gray-500 hover:text-gray-700 mr-4"><i className="far fa-thumbs-up"></i> Like</a>
                <a href="#" className="text-gray-500 hover:text-gray-700"><i className="far fa-comment-alt"></i> Reply</a>
              </div>
              <div className="flex items-center">
                <a href="#" className="text-gray-500 hover:text-gray-700 mr-4"><i className="far fa-flag"></i> Report</a>
                <a href="#" className="text-gray-500 hover:text-gray-700"><i className="far fa-share-square"></i> Share</a>
              </div> */}
            </div>
            <hr />
          </div>
        ))
      ) : (
        <p className="text-center">{t('articles.no_comments_yet')}</p>
      )}
    </div>
  )
}

export default PublishedArticleShow

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SEO from './SEO';
import API_BASE_URL from '../config';

export type UserInfoType = {
  bio: string;
};

const About = () => {
  const [data, setData] = useState<UserInfoType>();
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: API_BASE_URL + '/user/bio',
          method: 'get',
        }

        axios.request<UserInfoType>(configuration).then(
          (res) => {
            setData(res.data)
          }
        ).catch((err) => {
          console.error(err);
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount
  return (
    <div className='container mx-auto'>
      <SEO
        title='AnittaMendezFitness: Ejercicios,Rutinas,Circuitos,Salud,Bienestar'
        description='Descubre cómo mejorar tu salud con circuitos y rutinas de ejercicio y motivación para lograr tus objetivos de fitness.'
        image='https://anittamendezfitness.com/static/media/logo-fitness.e7fde95318d7ca1fc76f.png'
        url='https://anittamendezfitness.com/about'
      />
      <div className='relative isolate px-6 pt-14 lg:px-8'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='w-4/5 mx-auto'>
            <div className='text-center'>
              <p className='mt-6 text-lg leading-8 text-gray-600'>
                <div className="max-w-md mx-auto mt-8 p-4 shadow-lg rounded-lg bg-gray-100">
                  <h2 className="text-2xl font-bold text-gray-800 mb-2">{t('profile.about_me')}</h2>
                  <p className="text-gray-600 leading-relaxed">
                    {data?.bio}
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className='w-4/5 mx-auto'>
            <img
              src={require('../assets/images/parque-sur.png')}
              alt=''
              className='rounded-md'
            />
          </div>
        </div>
        <div
          className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff809e] to-[#277cd1] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default About

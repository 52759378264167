import { useState } from 'react';
import { useForm } from 'react-hook-form'
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';

interface IFormInput {
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Signup = () => {
  const { register, handleSubmit, getValues,  formState: { errors }, watch } = useForm<IFormInput>()
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate()

  const onSubmit = handleSubmit((values) => {
    const username = values.userName;
    const email = values.email;
    const password = values.password;

    const configuration = {
      url: API_BASE_URL + '/signup',
      method: 'post',
      data: {
        username,
        email,
        password
      },
    }


    axios.request(configuration).then((res: AxiosResponse) => {
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        navigate('/dashboard');
      }
    }).catch((err: AxiosError) => {
      console.log(err);
    })
  })

  return (
     <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>Sign Up</h2>
      </div>

      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        {errorMessage && <p className='peer-invalid:visible text-red-700 font-light'>{errorMessage}</p>}
        <form onSubmit={onSubmit} className='space-y-6'>
          <div>
            <div className='flex items-center justify-between'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>Userame</label>
            </div>
            <div className='mt-2'>
              <input
                {...register('userName', { required: true, minLength: 5 })}
                aria-invalid={errors.userName ? 'true' : 'false'}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
              {errors.userName?.type === 'required' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Username is required.</p>
              )}

              {errors.userName?.type === 'minLength' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Username length must be at least 5 characters.</p>
              )}
             </div>
          </div>

          <div>
            <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>Email address</label>
            <div className='mt-2'>
              <input
                {...register('email', { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ })}
                aria-invalid={errors.email ? 'true' : 'false'}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
               {errors.email?.type === 'required' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Email is required.</p>
              )}

              {errors.email?.type === "pattern" && (
                <p className='peer-invalid:visible text-red-700 font-light'>Email not valid.</p>
              )}
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>Password</label>
            </div>
            <div className='mt-2'>
              <input
                {...register('password', { required: true, minLength: 8 })}
                aria-invalid={errors.password ? 'true' : 'false'}
                type='password'
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
               {errors.password?.type === 'required' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Password is required.</p>
              )}

              {errors.password?.type === 'minLength' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Password length must be at least 8 characters.</p>
              )}
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='confirmPassword' className='block text-sm font-medium leading-6 text-gray-900'>Confirm Password</label>
            </div>
            <div className='mt-2'>
              <input
                {...register('confirmPassword', {
                  required: true,
                  validate: (value) => value === getValues('password')
                })}
                aria-invalid={errors.confirmPassword ? 'true' : 'false'}
                type='password'
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />

              {errors.confirmPassword?.type === 'required' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Confirm Password is required.</p>
              )}

              {watch("confirmPassword") !== watch("password") &&
                getValues("confirmPassword") ? (
                  <p className='peer-invalid:visible text-red-700 font-light'>Password does not match.</p>
                ) : null}

            </div>
          </div>
          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >Sign Up</button>
          </div>
      </form>
    </div>
    </div>
  )
}

export default Signup

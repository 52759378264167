import axios, { AxiosResponse, AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import ProfileSideMenu from './ProfileSideMenu';
import API_BASE_URL from '../config';

export type UserInfoType = {
  username: string;
  email: string;
  bio: string;
};

interface IFormInput {
  userName: string;
  email: string;
  bio: string;
}

const ProfileEditForm = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<IFormInput>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  const toggleProfileForm = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const configuration = {
          url: API_BASE_URL + '/user',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          },
        }

        axios.request<UserInfoType>(configuration).then(
          (res) => {
            setValue('userName', res.data.username);
            setValue('email', res.data.email);
            setValue('bio', res.data.bio);
          }
        ).catch((err) => {
          console.log(err);
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserData();
  }, [setValue]);


  const onSubmit = handleSubmit((values) => {
    const username = values.userName;
    const email = values.email;
    const bio = values.bio;

    const configuration = {
      url: API_BASE_URL + '/profile',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
      data: {
        username,
        email,
        bio
      },
    }

    axios.request(configuration).then((res: AxiosResponse) => {
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        setSuccessMessage('Profile updated successfully!')
        setTimeout(() => {
          toggleProfileForm();
        }, 2000);
      }
    }).catch((err: AxiosError) => {
      console.log(err);
    })
  })

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12 h-screen">
        {/* Sidebar (Menú) */}
        <aside className="col-span-3 bg-gray-200 text-cyan p-4 p-2">
          <h2 className="text-lg font-bold mb-4"></h2>
          <nav className="space-y-3">
          <ProfileSideMenu></ProfileSideMenu>
          </nav>  
        </aside>
        {/* Contenido Principal */}
        <main className="col-span-9 p-6 bg-gray-100">
          <h1 className="text-2xl font-bold mb-4">{t('profile.edit_profile')}</h1>
          {/* <!-- Jumbotron --> */}
          <form onSubmit={onSubmit} className='space-y-6'>
              {errorMessage && <p className='peer-invalid:visible text-red-700 font-light'>{errorMessage}</p>}
              {successMessage && <p className='peer-invalid:visible text-green-700 font-light'>{successMessage}</p>}
            <div>
              <div className='flex items-center justify-between'>
                <label className='block text-sm font-medium leading-6 text-gray-900'>{t('profile.username')}</label>
              </div>
              <div className='mt-2'>
                <input
                  {...register('userName', { required: true, minLength: 5 })}
                  aria-invalid={errors.userName ? 'true' : 'false'}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
                {errors.userName?.type === 'required' && (
                  <p className='peer-invalid:visible text-red-700 font-light'>Username is required.</p>
                )}

                {errors.userName?.type === 'minLength' && (
                  <p className='peer-invalid:visible text-red-700 font-light'>Username length must be at least 5 characters.</p>
                )}
              </div>
            </div>
            <div>
              <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>{t('profile.email')}</label>
              <div className='mt-2'>
                <input
                  {...register('email', { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ })}
                  aria-invalid={errors.email ? 'true' : 'false'}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
                  {errors.email?.type === 'required' && (
                  <p className='peer-invalid:visible text-red-700 font-light'>Email is required.</p>
                )}

                {errors.email?.type === "pattern" && (
                  <p className='peer-invalid:visible text-red-700 font-light'>Email not valid.</p>
                )}
              </div>
            </div>
            <div>
              <form className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('profile.bio')}</label>
                <textarea
                  {...register('bio')}
                  rows={4}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Leave a comment...">
                </textarea>
              </form>
            </div>
            <div>
              <button
                type='submit'
                className='flex w-100 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >{t('profile.save_profile')}</button>
            </div>
          </form>
       </main>
      </div>    
      </div>    
    )
}

export default ProfileEditForm

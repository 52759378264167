import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileSideMenu = () => {
  const { t }: { t: (key: string, options?: any) => string } = useTranslation();


  return (
    <div>
      {/* Sidebar (Menú) */}
        <Link
          className="block px-4 py-3 rounded-lg bg-gray-300 hover:bg-gray-400 transition duration-200 text-center font-semibold mb-2"
          to={`/profile`}>
              {t('profile.profile')}
        </Link>
        <Link
          className="block px-4 py-3 rounded-lg bg-gray-300 hover:bg-gray-400 transition duration-200 text-center font-semibold"
          to={`/edit-profile`}>
            {t('profile.edit_profile')}
        </Link>

    </div>
    )
}

export default ProfileSideMenu
